import { useInfiniteQuery, useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { selectedOrganizationSelector } from '../../selectors/organization';
import { getTemplates, getUserTemplates } from '../template';

export const useUserTemplatesQuery = (
  { getChunkSize, sortBy = '-saveTime', status = 'active' },
  options
) => {
  const activeOrganization = useSelector(selectedOrganizationSelector);

  const query = useInfiniteQuery(
    ['templates', 'user', 'index', { sortBy, status }],
    async ({ pageParam: cursor = 0 }) => {
      const limit = getChunkSize(cursor);
      const { result: templates, left } = await getUserTemplates({
        orgId: activeOrganization.id,
        skip: cursor,
        limit,
        sortby: sortBy,
        filter: status,
      });

      const result = { templates };
      if (left) {
        result.nextCursor = cursor + limit;
      }

      return result;
    },
    {
      ...options,
      getNextPageParam(page) {
        return page.nextCursor;
      },
    }
  );

  const data = query.data
    ? query.data.pages.flatMap(page => page.templates)
    : [];
  return {
    ...query,
    data,
    isAnyPageLoaded: data.length > 0,
  };
};

export const useSharedTemplatesQuery = (options, orgType) => {
  const query = useQuery(
    ['templates', 'shared', 'index'],
    () => getTemplates(orgType),
    options
  );
  return { ...query, data: query.data ?? [] };
};
