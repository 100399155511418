import styled from 'styled-components';
import { Tag as AntTag } from 'antd';

export const Card = styled.div`
  font-family: Poppins, sans-serif;
  width: 470px;
  aspect-ratio: 8/5;
  box-shadow: 0 1px 30px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 4px;
  transition: 400ms ease;
  overflow: hidden;
  padding: 20px;
  cursor: ${props => (props.pointer ? 'pointer' : 'default')};
  &:hover {
    box-shadow: 0 10px 50px rgba(0, 0, 0, 0.8);
  }
`;

export const Container = styled.div`
  padding: 0;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

Card.Body = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  flex-grow: 1;
`;

Card.Date = styled.span`
  font-size: 13px;
  line-height: 14px;
  font-style: italic;
`;

Card.Name = styled.h5`
  font-weight: bold;
  line-height: 20px;
  padding-bottom: 5px;
`;

Card.Description = styled.h5`
  line-height: 18px;
`;

export const StyledTag = styled(AntTag)`
  border-radius: 16px;
  font-size: 14px;
  border: none;
  color: #1890ff;
  background-color: #f5f5f5;
  padding: 6px 16px;
  &:hover {
    background-color: #ebebeb;
  }
`;
