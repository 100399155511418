import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Input, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
import {
  ArrowForwardOutlined,
  Visibility,
  Block,
  Create,
  QueryBuilder,
} from '@material-ui/icons';
import {
  copyDraft,
  getMessageIdFromDraftId,
  updateMsg,
  cancelEmails,
  cancelTexts,
  updateDraft,
  getMessageFromDraftId,
} from '../../../../redux/services';
import { fullloader, toast } from '../../../../redux/action';
import {
  Card,
  CardHeader,
  Button,
  ButtonGroup,
  CancelButton,
  Date,
  ColumnSpaceBetween,
  Group,
} from '../_CardUI/Card.styled';
import { formatDate, trimTextIfNeeded, formatTimestampToDate } from '../_utils';
import {
  ArchiveButton,
  UnarchiveButton,
  CopyButton,
  EmptySpace,
} from '../_CardUI/CardActionButtons.styled';
import Modal from '../../../Modal';
import { useIsFeatureEnabled } from '../../../../libs/feature-toggles/react';
import Feature from '../../../../infrastructure/features';
import { ACTIVE, SENT, ARCHIVED, SCHEDULED } from '../_utils/constants';
import Thumbnail from '../Thumbnail';

const DraftCard = ({
  onClick,
  archive,
  unarchive,
  draft,
  afterCopy,
  setActiveFilter,
  reloadDrafts,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isCopyModalOpen, setCopyModalOpen] = useState(false);
  const [isCreatingCopy, setCreatingCopy] = useState(false);
  const [draftCopyName, setDraftCopyName] = useState('');
  const [showCancelMessageModal, setShowCancelMessageModal] = useState(false);
  const [showEditMessageModal, setShowEditMessageModal] = useState(false);
  const openCopyModal = () => {
    setCopyModalOpen(true);
  };

  const closeCopyModal = () => {
    setCopyModalOpen(false);
  };

  const handleDraftNameChange = event => {
    setDraftCopyName(event.target.value);
  };

  const createDraftCopy = async () => {
    dispatch(fullloader(true, 'Creating a copy of the draft'));
    setCreatingCopy(true);

    try {
      await copyDraft({
        draftId: draft._id,
        copiedDraftName: draftCopyName || `${draft.name} - Copy`,
      });
      dispatch(toast('success', 'Draft copied.'));
      await afterCopy();
      closeCopyModal();
      setDraftCopyName('');
      setActiveFilter(ACTIVE.value);
    } catch {
      dispatch(toast('error', 'Error copying the draft'));
    } finally {
      setCreatingCopy(false);
      dispatch(fullloader(false));
    }
  };

  const isABEnabled = useIsFeatureEnabled(Feature.AB);

  const getActionIcons = status => {
    return (
      <>
        <CardHeader.Left>
          {draft.status === SCHEDULED.name && (
            <Date>
              <QueryBuilder style={{ fontSize: '14px' }} />
              {formatDate(draft.sendTime)}
            </Date>
          )}
        </CardHeader.Left>
        <CardHeader.Right>
          {status === ACTIVE.name && (
            <>
              <Tooltip title="Duplicate">
                <CopyButton onClick={openCopyModal} />
              </Tooltip>
              <Tooltip title="Archive">
                <ArchiveButton onClick={archive} />
              </Tooltip>
            </>
          )}
          {status === ARCHIVED.name && (
            <Tooltip title="Unarchive">
              <UnarchiveButton onClick={unarchive} />
            </Tooltip>
          )}
          {status === SENT.name && <EmptySpace />}
          {draft.AB && isABEnabled && <Card.AB>{'A/B'}</Card.AB>}
        </CardHeader.Right>
      </>
    );
  };

  const viewMessage = async () => {
    const { messageId, messageBId } = await getMessageIdFromDraftId(draft._id);
    window.open(
      `${process.env.REACT_APP_webUrl}/message?id=${messageId}`,
      '_blank'
    );

    if (messageBId) {
      window.open(
        `${process.env.REACT_APP_webUrl}/message?id=${messageBId}`,
        '_blank'
      );
    }
  };

  const getActionButtons = status => {
    switch (status) {
      case ACTIVE.name:
        return (
          <Tooltip title="Resumes working on draft">
            <Button onClick={onClick}>
              Open
              <ArrowForwardOutlined style={{ fontSize: '14px' }} />
            </Button>
          </Tooltip>
        );
      case SENT.name:
        return (
          <ButtonGroup>
            <Tooltip title="Opens sent message in browser">
              <Button onClick={viewMessage}>
                View
                <Visibility style={{ fontSize: '14px' }} />
              </Button>
            </Tooltip>
            <Tooltip title="Creates a new draft copy">
              <Button onClick={createDraftCopy}>
                Reuse
                <ArrowForwardOutlined style={{ fontSize: '14px' }} />
              </Button>
            </Tooltip>
          </ButtonGroup>
        );
      case ARCHIVED.name:
        return (
          <Tooltip title="Update archived draft">
            <Button onClick={onClick}>
              Update
              <ArrowForwardOutlined style={{ fontSize: '14px' }} />
            </Button>
          </Tooltip>
        );
      case SCHEDULED.name:
        return (
          <ButtonGroup>
            <Tooltip title="Cancels the currently-scheduled message">
              <CancelButton
                onClick={() => {
                  setShowCancelMessageModal(true);
                }}
                light
                color="#ff6161"
              >
                Cancel
                <Block style={{ fontSize: '14px' }} />
              </CancelButton>
            </Tooltip>
            <Tooltip title="Cancels the currently-scheduled message and opens it for editing">
              <Button
                onClick={() => {
                  setShowEditMessageModal(true);
                }}
              >
                Edit
                <Create style={{ fontSize: '14px' }} />
              </Button>
            </Tooltip>
          </ButtonGroup>
        );
    }
  };

  const cancelExistingMessage = async () => {
    try {
      const message = await getMessageFromDraftId(draft._id);
      if (message.status === 'Sent') {
        setShowCancelMessageModal(false);
        dispatch(
          toast('error', 'Cannot cancel a message that is already sent')
        );

        return;
      }

      dispatch(
        toast('success', 'Cancelling your message, this may take a minute.', 5)
      );

      await updateMsg({ msgId: message.id, cancelled: true });
      setShowCancelMessageModal(false);

      await cancelEmails(message.id);
      dispatch(toast('success', 'Emails successfully cancelled.', 4));

      await cancelTexts(message.id);
      dispatch(toast('success', 'Texts successfully cancelled.', 4));
      reloadDrafts();
    } catch (e) {
      console.error(e);
      dispatch(toast('error', 'Error while cancelling a message.'));
    }
  };

  const backToEdit = async () => {
    try {
      const message = await getMessageFromDraftId(draft._id);

      if (!message) {
        setShowEditMessageModal(false);
        dispatch(toast('error', 'Could not find message to edit.'));
        return;
      }

      await updateDraft({
        _id: draft._id,
        status: 'Active',
        editMsgId: message.id,
      });

      await updateMsg({
        msgId: message.id,
        cancelled: true,
      });

      setShowEditMessageModal(false);

      history.push(`/dashboard/sendmessage?draft=${draft._id}`);
    } catch (e) {
      console.error(e);
      dispatch(toast('error', 'Error while cancelling a message.'));
    }
  };

  return (
    <Card>
      <Thumbnail html={draft.html} />
      <Card.Body>
        <CardHeader>{getActionIcons(draft.status)}</CardHeader>
        <ColumnSpaceBetween>
          <Group>
            <Card.Name>{trimTextIfNeeded(draft.name, 60)}</Card.Name>
            {draft.subject ? (
              <Card.Subject>
                Subject: {trimTextIfNeeded(draft.subject, 54)}
              </Card.Subject>
            ) : null}
          </Group>
          <Card.Date>
            Created: {formatTimestampToDate(draft.createdAt)} <br />
            Last Updated: {formatDate(draft.saveTime)}
          </Card.Date>
        </ColumnSpaceBetween>
        {getActionButtons(draft.status)}
      </Card.Body>
      {isCopyModalOpen && (
        <Modal
          title="Duplicate Draft"
          primaryButton={{
            label: 'Save',
            isDisabled: draftCopyName.trim() === '',
            onClick: createDraftCopy,
            isLoading: isCreatingCopy,
          }}
          onClose={closeCopyModal}
        >
          <Input
            value={draftCopyName}
            onInput={handleDraftNameChange}
            placeholder="Draft Name"
          />
        </Modal>
      )}
      {showCancelMessageModal && (
        <Modal
          title={'Confirm Cancel'}
          onClose={() => {
            setShowCancelMessageModal(false);
          }}
          primaryButton={{
            label: 'YES',
            onClick: cancelExistingMessage,
          }}
          secondaryButton={{
            label: 'NO',
          }}
        >
          <div>
            <p>
              Are you sure you would like to cancel this message? Once you
              confirm, you will not be able to undo this.
            </p>
          </div>
        </Modal>
      )}
      {showEditMessageModal && (
        <Modal
          title={'Confirm Edit'}
          onClose={() => {
            setShowEditMessageModal(false);
          }}
          primaryButton={{
            label: 'YES',
            onClick: backToEdit,
          }}
          secondaryButton={{
            label: 'NO',
          }}
        >
          <div>
            <p>
              Are you sure you would like to edit this message? Editing this
              message will cancel it. Once you confirm, you will not be able to
              undo this.
            </p>
          </div>
        </Modal>
      )}
    </Card>
  );
};

export default DraftCard;
