import React, { useState, useEffect } from 'react';
import {
  Drawer,
  IconButton,
  TextField,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import { Close, Sync, Add, HighlightOff } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { updateOrganization } from '../../../../../redux/services';
import {
  userOrganization,
  useToast,
  useUpdateUIState,
} from '../../../../../redux/action';
import {
  Container,
  Header,
  Content,
  SaveBtn,
  Title,
  Group,
} from '../EditAPIConnectionDrawer/EditAPIConnectionDrawer.styled';
import { Title as ContentTitle } from '../../GeneralInfo/GeneralInfo.styled';
import {
  Text,
  AddBtn,
  FitContent,
  ListContainer,
  SmallText,
} from './EditSyncSettingsDrawer.styled';

const EditSyncSettingsDrawer = ({
  selectedOrg,
  isEditSyncSettingsDrawerVisible,
  setEditSyncSettingsDrawerVisibility,
  orgTypes,
}) => {
  const dispatch = useDispatch();
  const { errorToast, successToast } = useToast();
  const { fullLoader } = useUpdateUIState();
  const [syncedStatuses, setSyncStatuses] = useState(
    selectedOrg?.assocStatus || ''
  );
  const [syncedMemberships, setSyncedMemberships] = useState(
    selectedOrg?.associationIDs || ''
  );
  const [excludedSubtypes, setExcludedSubtypes] = useState(
    selectedOrg?.excludingBillTypes || []
  );
  const [phoneType, setPhoneType] = useState(selectedOrg?.phoneType || '');
  const [coeCategory, setCOECategory] = useState(
    selectedOrg?.magicCOECategory || ''
  );
  const [AMSListCategories, setAMSListCategories] = useState(
    selectedOrg?.listCategories?.map(categoryCodeObj => {
      const [category, code] = Object.entries(categoryCodeObj)[0];
      return { category, code };
    }) || []
  );
  const [eventsEnabled, setEventsEnabled] = useState(
    selectedOrg?.eventsEnabled || false
  );
  const [
    skipEventSyncOfInactiveRegistrants,
    setSkipEventSyncOfInactiveRegistrants,
  ] = useState(
    eventsEnabled ? selectedOrg?.skipEventSyncOfInactiveRegistrants : false
  );
  const [memberTypeAsSubtype, setMemberTypeAsSubtype] = useState(
    selectedOrg?.searchByMemberType || false
  );
  const [isEventsSyncVisible, setEventsSyncVisibility] = useState(false);
  const [isCOECategoryVisible, setCOECategoryVisible] = useState(false);

  useEffect(() => {
    if (orgTypes && orgTypes.length) {
      const magicId = orgTypes.find(orgType => orgType.name === 'magic')?.id;
      setEventsSyncVisibility(magicId === selectedOrg.orgType);
      setCOECategoryVisible(magicId === selectedOrg.orgType);
    }
  }, [orgTypes]);

  const transformAMSListCategories = arrayOfAMSListCategories =>
    arrayOfAMSListCategories.map(obj => {
      const newObj = {};
      newObj[obj.category] = obj.code;
      return newObj;
    });

  useEffect(() => {
    if (!eventsEnabled) {
      setSkipEventSyncOfInactiveRegistrants(false);
    }
  }, [eventsEnabled]);

  const closeDrawer = () => {
    setEditSyncSettingsDrawerVisibility(false);
  };

  const handleSubmit = async () => {
    try {
      fullLoader(true, 'Updating organization sync settings.');

      const date = new Date();
      const listCategories = transformAMSListCategories(AMSListCategories);
      const ramcoTypeId = orgTypes.find(
        orgType => orgType.name === 'ramco'
      )?.id;
      const orgData = {
        id: selectedOrg.id,
        eventsEnabled,
        skipEventSyncOfInactiveRegistrants,
        searchByMemberType: memberTypeAsSubtype,
        assocStatus: syncedStatuses,
        associationIDs: syncedMemberships,
        excludingBillTypes: excludedSubtypes,
        listCategories,
        //usingAPILists should be always true for RAMCO orgs, otherwise if they have list categories
        usingAPILists:
          !!listCategories?.length || selectedOrg.orgType === ramcoTypeId,
        updatedDate: date.toString(),
        orgType: selectedOrg.orgType,
        importMagic: true, //To ensure the AMS syncs will be enabled
        magicCOECategory: coeCategory,
      };

      const updatedOrg = await updateOrganization(orgData);
      if (!updatedOrg) {
        errorToast('Failed to update organization sync settings.');
        fullLoader(false);
        return;
      }

      dispatch(userOrganization(updatedOrg));
      successToast('Successfully updated organization AMS sync settings.');

      closeDrawer();
      fullLoader(false);
    } catch (e) {
      console.error(e);
      errorToast('Error updating organization AMS sync settings.');
      fullLoader(false);
    }
  };

  const deleteAMSList = index => {
    setAMSListCategories(prevAMSListCategories =>
      prevAMSListCategories.filter((_, i) => i !== index)
    );
  };

  const handleAddAMSList = () => {
    const lastAMSList =
      AMSListCategories.length &&
      AMSListCategories[AMSListCategories.length - 1];
    if (lastAMSList?.category !== '' && lastAMSList?.code !== '') {
      setAMSListCategories([...AMSListCategories, { category: '', code: '' }]);
    }
  };

  const updateAMSList = (index, field, value) => {
    const updatedAMSList = [...AMSListCategories];
    updatedAMSList[index][field] = value;
    setAMSListCategories(updatedAMSList);
  };

  const splitStringToArray = inputString =>
    inputString ? inputString.split(';') : [];

  return (
    <Drawer
      anchor={'right'}
      onClose={closeDrawer}
      open={isEditSyncSettingsDrawerVisible}
    >
      <Container>
        <Header>
          <Group>
            <IconButton onClick={closeDrawer} style={{ color: 'white' }}>
              <Close />
            </IconButton>
            <Title>Editing: Sync Settings</Title>
          </Group>
          <SaveBtn onClick={handleSubmit}>Save</SaveBtn>
        </Header>
        <Content>
          <ContentTitle>
            <Sync size="small" />
            Sync Settings
          </ContentTitle>
          <SmallText>
            Multiple values must be separated by semicolons.
          </SmallText>
          <TextField
            label="Synced Statuses"
            variant="outlined"
            size="small"
            value={syncedStatuses?.join(';')}
            onChange={e => setSyncStatuses(splitStringToArray(e.target.value))}
          />
          <TextField
            label="Synced Memberships"
            variant="outlined"
            size="small"
            value={syncedMemberships?.join(';')}
            onChange={e =>
              setSyncedMemberships(splitStringToArray(e.target.value))
            }
          />
          <TextField
            label="Excluded Subtypes"
            variant="outlined"
            size="small"
            value={excludedSubtypes?.join(';')}
            onChange={e =>
              setExcludedSubtypes(splitStringToArray(e.target.value))
            }
          />
          <TextField
            label="Synced Phone Field"
            variant="outlined"
            size="small"
            value={phoneType}
            onChange={e => setPhoneType(e.target.value)}
          />
          {AMSListCategories.length ? (
            <ListContainer>
              <Text>Synced AMS Lists</Text>
              {AMSListCategories.map(({ category, code }, index) => (
                <Group key={index}>
                  <TextField
                    label="List Category"
                    variant="outlined"
                    size="small"
                    value={category}
                    onChange={e =>
                      updateAMSList(index, 'category', e.target.value)
                    }
                  />
                  <Add size="small" />
                  <TextField
                    label="List Code"
                    variant="outlined"
                    size="small"
                    value={code}
                    onChange={e => updateAMSList(index, 'code', e.target.value)}
                  />
                  <IconButton
                    onClick={() => deleteAMSList(index)}
                    style={{ color: '#ff6161' }}
                  >
                    <HighlightOff size="small" />
                  </IconButton>
                </Group>
              ))}
            </ListContainer>
          ) : (
            ''
          )}
          <AddBtn onClick={handleAddAMSList}>+ Add synced AMS List</AddBtn>
          <ListContainer gap={'5px'}>
            <Text>Additional Settings</Text>
            <FitContent>
              <FormControlLabel
                control={
                  <Switch
                    checked={memberTypeAsSubtype}
                    color="primary"
                    onChange={e => setMemberTypeAsSubtype(e.target.checked)}
                  />
                }
                size="small"
                label="Use Member Types as Subtypes"
              />
            </FitContent>
            {isEventsSyncVisible && (
              <FitContent>
                <FormControlLabel
                  control={
                    <Switch
                      checked={eventsEnabled}
                      color="primary"
                      onChange={e => setEventsEnabled(e.target.checked)}
                    />
                  }
                  label="Enable Events Sync"
                />
              </FitContent>
            )}
            {eventsEnabled ? (
              <FitContent>
                <FormControlLabel
                  control={
                    <Switch
                      checked={skipEventSyncOfInactiveRegistrants}
                      color="primary"
                      onChange={e =>
                        setSkipEventSyncOfInactiveRegistrants(e.target.checked)
                      }
                    />
                  }
                  label="Skip Sync of Inactive Event Registrants"
                />
              </FitContent>
            ) : (
              ''
            )}
            {isCOECategoryVisible && (
              <TextField
                label="Rapattoni COE Category (Member Education)"
                variant="outlined"
                size="small"
                value={coeCategory}
                onChange={e => setCOECategory(e.target.value)}
              />
            )}
          </ListContainer>
        </Content>
      </Container>
    </Drawer>
  );
};

export default EditSyncSettingsDrawer;
