import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const FilterContainer = styled.div`
  padding: 10px;
  display: flex;
  gap: 10px;
`;

export const Menu = styled.ul`
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  padding-left: 25px;
`;

export const MenuItem = styled.li`
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  padding: 11px 20px;
  margin-right: 6px;
  border-radius: 2px;
  text-align: center;
  align-items: center;
  color: #007ade;
  display: flex;
  border: 1px solid #509ee5;
  transition: all 0.3s ease-in;
  background-color: #ebf0f5;
  line-height: 14px;
  &:hover,
  &.active {
    cursor: pointer;
    color: white;
    background-color: #007ade;
    border: 1px solid #007ade;
  }
  @media (max-width: 1400px) {
    padding: 5px 20px;
    height: 38px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 20px;
  padding-top: 10px;
`;
