import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { PeopleAlt, Save } from '@material-ui/icons';
import { updateDraft, getDraftBy_id } from '../../../../../redux/services';
import ItalicText from './Components/italicText';
import Container from './Components/container';
import Wrapper from './Components/wrapper';
import Wrap from './Components/wrap';
import Box from './Components/box';
import Divider from './Components/divider';
import Button from './Components/btn';
import SolidButton from './Components/solidBtn';
import PrimaryBtn from './Components/primaryBtn';
import SelectDropDown from './Components/selectDropDown';
import AudienceNameAndDescription from './Components/audienceNameAndDescription';
import ApplyStandAloneAudienceDialog from './Components/ApplyStandAloneAudienceDialog';
import Text from './Components/text';
import AudienceIconStyle from './Components/audienceIcon.styled';
import Asterisk from './Components/asterisk.styled';
import {
  audienceSelector,
  selectedOrganizationSelector,
} from '../../../../../redux/selector';
import {
  selectMenuItem,
  selectSubMenuItem,
  updateStandAloneAudienceAction,
  saveNewStandAloneAuidenceAction,
  toast,
} from '../../../../../redux/action';
import {
  SUB_MENU_CONFIG,
  TOPICS,
  GROUPS,
  FILTERS,
  CONTACTS,
} from '../../constants';
import { convertAudienceDataForDraft } from '../../../../_shared/utils';
import ProceedToPreview from './Components/proceedToPreview';
import SaveCopyAudienceDialog from './Components/dialogForSavingAudienceCopy';
import BackButton from '../../../Components/BackButton';
const FILE = 'File';
const NEW_SAVED_AUDIENCE = 'New Saved Audience';
const APPLY_SAVED_AUDIENCE = 'Apply Saved Audience';
const UNSAVED_CHANGES = 'Unsaved changes';
const SAVED = 'Saved';
const SAVING = 'Saving';
const SMALL_SCREEN_BREAKPOINT = '1365px';
const REORDER_BREAKPOINT = '1225px';

const Menu = ({
  withoutPadding,
  withoutTitle,
  withoutPreview,
  withoutFileMenu,
  withoutApplyFileMenu,
  alignTitleToLeft,
  centerTabs,
  isStandAloneAudience: strictIsStandaloneAudience,
  disableAutosave,
}) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const audience = useSelector(audienceSelector);
  const organizationId = useSelector(selectedOrganizationSelector).id;
  const query = queryString.parse(location.search);
  const isMounted = useRef(false);

  const [draft, setDraft] = useState({});
  const [savedStatus, setSavedStatus] = useState(SAVED);
  const [showCopyAudienceDialog, setShowCopyAudienceDialog] = useState(false);
  const [showApplyStandAloneAudienceDialog, setApplyStandAloneAudienceDialog] =
    useState(false);

  const getDraft = async () => {
    try {
      const draft = await getDraftBy_id(query.draft);
      setDraft(draft);
    } catch (e) {
      dispatch(toast('error', 'Failed to fetch draft'));
    }
  };

  useEffect(() => {
    if (query.draft) {
      getDraft();
    }
  }, [query.draft]);

  const interval = 1500;

  const autoSaveAudience = async () => {
    if (disableAutosave) {
      return;
    }

    setSavedStatus(SAVING);

    if (isStandAloneAudience()) {
      await saveSandAloneAudience();
    } else {
      await saveAudienceToDraft();
    }

    setSavedStatus(SAVED);
  };

  useEffect(() => {
    // to skip autosave on mount
    if (isMounted.current) {
      setSavedStatus(UNSAVED_CHANGES);
      const audienceAutoSaveTimer = setTimeout(autoSaveAudience, interval);
      return () => clearTimeout(audienceAutoSaveTimer);
    } else {
      isMounted.current = true;
    }
  }, [
    audience._id,
    audience.name,
    audience.description,
    JSON.stringify(convertAudienceDataForDraft(audience)),
  ]);

  useEffect(() => {
    if (query.new === 'true' && audience._id) {
      history.replace(`/dashboard/audience?audienceId=${audience._id}`);
    }
  }, [audience._id]);

  const handleMenuItemClick = menuItemName => {
    dispatch(selectMenuItem(menuItemName));

    if (SUB_MENU_CONFIG[menuItemName].length) {
      dispatch(selectSubMenuItem(SUB_MENU_CONFIG[menuItemName][0]));
    }
  };

  const saveAudienceToDraft = async () => {
    try {
      const audienceDataForDraft = convertAudienceDataForDraft(audience);
      await updateDraft({ _id: query.draft, ...audienceDataForDraft });
    } catch (e) {
      dispatch(toast('error', `Error saving audience to draft. ${e.message}`));
    }
  };

  const returnBackToSavedAudiences = () => {
    history.push('/dashboard/drafts?tab=audiences');
  };

  const saveSandAloneAudience = async () => {
    if (audience._id) {
      await dispatch(updateStandAloneAudienceAction(audience._id, audience));
    } else {
      await dispatch(saveNewStandAloneAuidenceAction(audience, organizationId));
    }
  };

  const isStandAloneAudience = () => {
    return (
      strictIsStandaloneAudience || query.audienceId || query.new === 'true'
    );
  };

  const proceedToEdit = () => {
    saveAudienceToDraft();
    history.push(`/dashboard/sendmessage?draft=${query.draft}`);
  };

  const fileSaveOptionsOnChange = value => {
    if (value === NEW_SAVED_AUDIENCE) {
      return setShowCopyAudienceDialog(true);
    } else if (value === APPLY_SAVED_AUDIENCE) {
      setApplyStandAloneAudienceDialog(true);
    }
  };

  const restrictTextSize = (text, size = 24) => {
    if (text && text.length > size) {
      return text.substring(0, size - 3) + '...';
    }
    return text ? text : '';
  };

  const fileMenuNames = useMemo(() => {
    const menus = [NEW_SAVED_AUDIENCE];

    if (!withoutApplyFileMenu) {
      menus.push(APPLY_SAVED_AUDIENCE);
    }

    return menus;
  }, [withoutApplyFileMenu]);

  return (
    <Container withoutPadding={withoutPadding}>
      <Wrap width={SMALL_SCREEN_BREAKPOINT} alignTitleToLeft={alignTitleToLeft}>
        {!withoutTitle && (
          <>
            <BackButton
              onClick={
                isStandAloneAudience()
                  ? () => {
                      saveSandAloneAudience();
                      returnBackToSavedAudiences();
                    }
                  : proceedToEdit
              }
              tooltipText="Back to Design"
            />
            <AudienceIconStyle width={SMALL_SCREEN_BREAKPOINT}>
              <PeopleAlt style={{ marginRight: 7 }} />
              <b>Audience</b>
            </AudienceIconStyle>
          </>
        )}
        {!withoutFileMenu && (
          <SelectDropDown
            value={FILE}
            menuItemNames={fileMenuNames}
            onChange={fileSaveOptionsOnChange}
          />
        )}
        {isStandAloneAudience() ? (
          <AudienceNameAndDescription
            disableAutosave={disableAutosave}
            isOpen={query.new === 'true'}
            restrictTextSize={restrictTextSize}
          />
        ) : (
          <Text>{restrictTextSize(draft.name, 20)}</Text>
        )}
      </Wrap>
      <SaveCopyAudienceDialog
        isOpen={showCopyAudienceDialog}
        close={() => {
          setShowCopyAudienceDialog(false);
        }}
      />
      <ApplyStandAloneAudienceDialog
        isOpen={showApplyStandAloneAudienceDialog}
        close={() => {
          setApplyStandAloneAudienceDialog(false);
        }}
      />
      <Wrapper width={REORDER_BREAKPOINT} centerTabs={centerTabs}>
        <PrimaryBtn
          onClick={() => handleMenuItemClick(TOPICS)}
          className={audience.selectedMenuItemName === TOPICS ? 'active' : ''}
          color="#007ADE"
          backColor="#EAEFF4"
          colorOnHover="#007ADE"
        >
          {TOPICS}
          {audience.selectedTopicsList.length === 0 ? (
            <Asterisk>*</Asterisk>
          ) : (
            ''
          )}
        </PrimaryBtn>
        <Divider />
        {[GROUPS, FILTERS, CONTACTS].map(menuItemName => {
          return (
            <Button
              key={menuItemName}
              onClick={() => handleMenuItemClick(menuItemName)}
              className={
                audience.selectedMenuItemName === menuItemName ? 'active' : ''
              }
              disabled={audience.selectedTopicsList.length === 0}
            >
              {menuItemName}
            </Button>
          );
        })}
      </Wrapper>
      {!withoutPreview && (
        <Box>
          <ItalicText>{savedStatus}</ItalicText>
          {isStandAloneAudience() ? (
            <SolidButton
              disabled={audience.selectedTopicsList.length === 0}
              onClick={() => {
                saveSandAloneAudience();
                returnBackToSavedAudiences();
              }}
              padding="0 18px 0 28px"
            >
              <Save
                style={{ fontSize: 12, position: 'absolute', top: 7, left: 8 }}
              />
              Save
            </SolidButton>
          ) : (
            <ProceedToPreview
              saveAudienceToDraft={saveAudienceToDraft}
              isDisabled={savedStatus !== SAVED}
            />
          )}
        </Box>
      )}
    </Container>
  );
};

export default Menu;
