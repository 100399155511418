import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import { InfoCircleOutlined } from '@ant-design/icons';
import { CircularProgress } from '@material-ui/core';
import {
  getTemplates,
  updateDraft as updateDraftService,
  saveNewDraftService,
} from '../../../../redux/services';
import { selectedOrganizationSelector } from '../../../../redux/selectors/organization';
import { userDetailSelector } from '../../../../redux/selectors/user';
import {
  fullloader,
  toast,
  resetAudienceBuilder,
} from '../../../../redux/action';
import queryString from 'query-string';
import {
  Container,
  MessageContainer,
  Text,
  FullLengthContainer,
} from '../_PageUI/Page.styled';
import TemplateCard from '../TemplateCard';

const SharedTemplates = ({
  cardsBySearch,
  loadingCardsBySearch,
  searchValue,
  sharedTemplatesRef,
}) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { draft: draftId, apply } = queryString.parse(location.search);
  const applyingToDraft = apply === '1';
  const selectedOrganization = useSelector(selectedOrganizationSelector);
  const userDetail = useSelector(userDetailSelector);
  const [sharedTemplates, setSharedTemplates] = useState([]);

  const loadData = useCallback(async () => {
    try {
      const data = await getTemplates(selectedOrganization.orgType);
      sharedTemplatesRef.current = data;
      setSharedTemplates(data);
    } catch (e) {
      dispatch(toast('error', 'Error loading shared templates.'));
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(fullloader(true, 'Loading Shared Templates...'));
    loadData().finally(() => {
      dispatch(fullloader(false));
    });
  }, [dispatch, loadData]);

  const applySharedTemplateToDraft = async template => {
    try {
      dispatch(fullloader(true, 'Applying selected template...'));
      let draftIdentifier = draftId;

      if (draftId === 'new') {
        const draft = await saveNewDraftService({
          html: template.html,
          design: template.design,
          organizationId: selectedOrganization.id,
          userId: userDetail.id,
        });

        draftIdentifier = draft._id;
      } else {
        await updateDraftService({
          _id: draftId,
          html: template.html,
          design: template.design,
        });
      }

      dispatch(fullloader(false));
      history.push(`/dashboard/sendmessage?draft=${draftIdentifier}`);
    } catch (e) {
      dispatch(
        toast('error', `Error applying template to draft. ${e.message}`)
      );
    }
  };

  const openNoteRouterTemplate = async template => {
    dispatch(fullloader(true, 'Applying selected template...'));
    const draft = await saveNewDraftService({
      design: template.design,
      html: template.html,
      organizationId: selectedOrganization.id,
      userId: userDetail.id,
    });
    dispatch(resetAudienceBuilder());
    dispatch(fullloader(false));
    history.push(`/dashboard/sendmessage?draft=${draft._id}`);
  };

  const sharedTemplatesText =
    "These are templates created by outside designers, such as from NoteRouter. The originals can't be edited or deleted, but you can use them to create your own derivative works however you'd like.";

  const getSearchResults = () => {
    if (loadingCardsBySearch)
      return (
        <FullLengthContainer>
          <CircularProgress size={50} />
        </FullLengthContainer>
      );

    if (cardsBySearch?.length)
      return cardsBySearch.map(template => (
        <TemplateCard
          key={template.id}
          template={template}
          onClick={
            applyingToDraft
              ? () => applySharedTemplateToDraft(template)
              : () => openNoteRouterTemplate(template)
          }
        />
      ));

    return <>No Templates found.</>;
  };

  return (
    <>
      <MessageContainer>
        <InfoCircleOutlined />
        <Text>{sharedTemplatesText}</Text>
      </MessageContainer>
      <Container>
        {cardsBySearch?.length || loadingCardsBySearch || searchValue ? (
          getSearchResults()
        ) : (
          <>
            {sharedTemplates.map(template => (
              <TemplateCard
                key={template.id}
                template={template}
                onClick={
                  applyingToDraft
                    ? () => applySharedTemplateToDraft(template)
                    : () => openNoteRouterTemplate(template)
                }
              />
            ))}
          </>
        )}
      </Container>
    </>
  );
};

export default SharedTemplates;
